<template>
    <div>
        <div class="register" :style="registerback">
            <div class="register-box mainwidth">
                <div class="register-con">
                    <div class="reg-title">
                        <p>企业用户注册</p>
<!--                      <router-link  style="font-size:18px;color: #fff;margin-bottom: 0;margin-left: 100px" to="./RegisterPhone">手机号注册</router-link>-->
                        <LeftSquareOutlined  @click="$router.back()"/>
                    </div>
                    <div class="reg-form">
                        <a-form layout="vertical" :model="formRegister" ref="ruleForm">
                            <a-form-item>
                                <a-input v-model:value="formRegister.orgName" placeholder="请输入企业名称">
                                    <template #prefix><UserOutlined /></template>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input v-model:value="formRegister.user" placeholder="请设置登录账号">
                                    <template #prefix><UserOutlined /></template>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input v-model:value="formRegister.password" type="password" placeholder="请设置登录密码">
                                    <template #prefix><LockOutlined /></template>
                                </a-input>
                            </a-form-item>
                            <a-form-item>
                                <a-input v-model:value="formRegister.repassword" type="password" placeholder="请再次输入密码">
                                    <template #prefix><LockOutlined /></template>
                                </a-input>
                            </a-form-item>
                          <a-form-item>
                            <a-input v-model:value="formRegister.phone" placeholder="请输入手机号">
                              <template #prefix><MobileOutlined /></template>
                            </a-input>
                          </a-form-item>
                            <!--

                            <div class="code-box">
                                <a-form-item>
                                    <a-input v-model:value="formRegister.vercode" placeholder="请输入短信验证码">
                                        <template #prefix><MessageOutlined /></template>
                                    </a-input>
                                </a-form-item>
                                <a-form-item>
                                    <a-button type="primary">
                                        获取短信
                                    </a-button>
                                </a-form-item>
                            </div> -->
                            <a-form-item>
                                <a-radio-group v-model:value="formRegister.type">
                                    <a-radio value="E">企业</a-radio>
                                    <a-radio value="I">个体工商户</a-radio>
                                </a-radio-group>
                            </a-form-item>
                            <a-form-item>
                                <a-checkbox @change="onChange">
                                    请认真阅读
                                </a-checkbox>
                                <span class="termssp" @click="infoShow = true">《用户使用条款》</span>
                                <span style="color: #ffffff">并同意该使用条款</span>
                                <a-modal
                                    v-model:visible="infoShow"
                                    title="信息提示"
                                    :footer="null"
                                >
                                    <div style="height: 400px;overflow-y: scroll;">
                                        <p style="text-align: center;">《最终用户使用条款》</p>
                                        <p style="text-indent: 20px;">“龙哈智能产融综合服务平台”（以下简称：本平台）用户需同意下述所有服务条款并完成注册程序， 才能成为本平台的正式用户并使用本平台提供的各项服务。平台的所有权、运营权、解释权以及本协议条款的修改权归本平台所有。</p>
                                        <p>一、服务内容</p>
                                        <p style="text-indent: 20px;">本平台通过国际互联网络等手段为用户提供综合金融服务。本平台有权在必要时修改服务条款，服务条款一旦发生变动，将会在首页上提示修改内容或通过其他形式告知用户。 如果用户不同意所改动的内容，可以主动取消获得的网络服务。如果用户继续享用网络服务，则视为接受服务条款的变动。</p>
                                        <p style="text-indent: 20px;">本平台保留随时修改或中断服务而不需通知用户的权利。 本平台行使修改或中断服务的权利，不需对用户或第三方负责。本平台发布的包含但不限于通知、公告、声明等内容是本协议的一部分。</p>
                                        <p>二、用户账号</p>
                                        <p style="text-indent: 20px;">用户应按照注册要求填写真实信息。用户有义务保证密码和帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害， 由用户自行承担全部责任，本平台不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题， 应立即修改帐号密码并妥善保管，如有必要，请通知本平台。因黑客行为或用户的保管疏忽导致帐号非法使用，本平台不承担任何责任。</p>
                                        <p>三、注册要求</p>
                                        <p style="text-indent: 20px;">1、用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本平台任何页面发布、转载、传送含有下列内容的信息，否则本平台一经发现有权自行处理并不通知用户：</p>
                                        <p style="text-indent: 20px;">(1)违反宪法确定的基本原则的；</p>
                                        <p style="text-indent: 20px;">(2)危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</p>
                                        <p style="text-indent: 20px;">(3)损害国家荣誉和利益的；</p>
                                        <p style="text-indent: 20px;">(4)煽动民族仇恨、民族歧视，破坏民族团结的；</p>
                                        <p style="text-indent: 20px;">(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                                        <p style="text-indent: 20px;">(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                                        <p style="text-indent: 20px;">(7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p>
                                        <p style="text-indent: 20px;">(8)侮辱或者诽谤他人，侵害他人合法权益的；</p>
                                        <p style="text-indent: 20px;">(9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p>
                                        <p style="text-indent: 20px;">(10)以非法民间组织名义活动的；</p>
                                        <p style="text-indent: 20px;">(11)含有法律、行政法规禁止的其他内容的。</p>
                                        <p style="text-indent: 20px;">2、用户承诺对其发表或者上传于本平台的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、 表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权； 如用户违反本条规定造成本平台被第三人索赔的，用户应全额补偿本平台一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)；</p>
                                        <p style="text-indent: 20px;">3、当第三方认为用户发表或者上传于本平台的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本平台发送权利通知书时，用户同意本平台可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本平台将不会自动恢复上述删除的信息；</p>
                                        <p style="text-indent: 20px;">4、如用户在使用网络服务时违反下述任何规定，本平台有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</p>
                                        <p style="text-indent: 20px;">(1)不得为任何非法目的而使用网络服务系统；</p>
                                        <p style="text-indent: 20px;">(2)遵守所有与网络服务有关的网络协议、规定和程序；</p>
                                        <p style="text-indent: 20px;">(3)不得利用本平台进行任何可能对互联网的正常运转造成不利影响的行为；</p>
                                        <p style="text-indent: 20px;">(4)不得利用本平台进行任何不利于本平台的行为。</p>
                                        <p>四、隐私保护</p>
                                        <p style="text-indent: 20px;">1、用户注册本平台相关服务时，根据网站要求提供相关企业信息；在您使用龙江智能产融结合服务平台服务、参加网站活动、或访问网站网页时，网站自动接收并记录的您浏览器上的服务器数据，包括但不限于IP地址、网站Cookie中的资料及您要求取用的网页记录；龙江智能产融结合服务平台承诺不公开或透露您的密码、手机号码等在本平台的非公开信息。除非因用户本人的需要、法律或其他合法程序的要求、服务条款的改变或修订等。</p>
                                        <p style="text-indent: 20px;">2、本平台不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本平台的非公开内容，但下列情况除外：</p>
                                        <p style="text-indent: 20px;">(1)事先获得用户的明确授权；</p>
                                        <p style="text-indent: 20px;">(2)根据有关的法律法规要求；</p>
                                        <p style="text-indent: 20px;">(3)按照相关政府主管部门的要求；</p>
                                        <p style="text-indent: 20px;">(4)为维护社会公众的利益。</p>
                                        <p style="text-indent: 20px;">3、本平台可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同 意承担与本平台同等的保护用户隐私的责任，则本平台有权将用户的注册资料等提供给该第三方。</p>
                                        <p style="text-indent: 20px;">4、在不透露单个用户隐私资料的前提下，本平台有权对整个用户数据库进行分析并对用 户数据库进行商业上的利用。</p>
                                        <p>五、责任说明</p>
                                        <p style="text-indent: 20px;">1、本平台无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。</p>
                                        <p style="text-indent: 20px;">2、本平台不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本平台实际控制的任何网页上的内容，本平台不承担任何责任。</p>
                                        <p style="text-indent: 20px;">3、 对于因不可抗力或本平台不能控制的原因造成的网络服务中断或其它缺陷，本平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</p>
                                        <p style="text-indent: 20px;">4、 本平台有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，本平台对用户和任何第三人均无需承担任何责任。</p>
                                        <p style="text-indent: 20px;">5、用户通过龙江智能产融结合服务平台发生的对接行为以及对接成功后发生的金融活动，由对接双方分别承担相应法律责任，本平台不承担由此引发的法律责任。</p>
                                        <p style="text-indent: 20px;">6、本平台不承诺用户对接的成功，因任何原因（包括但不限于用户发布的资料、信息不真实、不合法、不完整等）导致对接不成功的，本平台不承担法律责任。</p>
                                        <p>六、版权说明</p>
                                        <p style="text-indent: 20px;">任何用户在本平台发表任何形式的信息，均表明该用户主动将该信息的发表权、汇编权、修改权、信息网络传播权无偿独家转让给龙江智能产融结合服务平台主管部门。本协议已经构成《著作权法》第二十五条所规定的书面协议，并在用户同意接受本注册协议时生效，其效力及于用户此后在龙江智能产融结合服务平台发布的任何内容。 用户同意并明确了解上述条款，不将已发表于本平台的信息，以任何形式发布或授权其它网站（及媒体）使用。</p>
                                        <p>七、附则</p>
                                        <p style="text-indent: 20px;">1、本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
                                        <p style="text-indent: 20px;">2、因本协议的履行或龙江智能产融结合服务平台的使用产生的争议，双方应协商解决， 协商不成的，任何一方均应向黑龙江仲裁委申请冲裁解决。</p>
                                        <p style="text-indent: 20px;">3、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的 其余条款仍应有效并且有约束力。</p>
                                        <p style="text-indent: 20px;">4、用户点击本协议 “下一步”按钮即视为完全接受本协议，在点击之前请再次确认已知悉并完全理解本协议的全部内容。</p>
                                    </div>
                                </a-modal>
                            </a-form-item>
                            <a-form-item>
                                <a-button type="primary" html-type="submit" @click="onRegister">
                                    立即注册
                                </a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    LeftSquareOutlined,
    UserOutlined,
    LockOutlined
    // MobileOutlined,
    // MessageOutlined
} from '@ant-design/icons-vue'
import md5 from 'js-md5'

export default {
    components:{
        LeftSquareOutlined,
        UserOutlined,
        LockOutlined
        // MobileOutlined,
        // MessageOutlined
    },
    data() {
        return {
            registerback:{
                backgroundImage: "url(" + require("../assets/images/register.jpg") + ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%"
            },
            formRegister: {
                orgName: '',
                user: '',
                password: '',
                repassword:'',
                phone:'',
                vercode:'',
                type:'',
                terms: false
            },
            infoShow: false
        }
    },
    methods: {
        onChange (e) {
            this.formRegister.terms = e.target.checked
        },
        onRegister () {
            if (this.formRegister.orgName === '') {
                this.$message.info('请输入企业名称')
                return false
            } else if (this.formRegister.user === '') {
                this.$message.info('请设置登录账号')
                return false
            } else if (this.formRegister.password === '') {
                this.$message.info('请设置登录密码')
                return false
            } else if (this.formRegister.repassword === '') {
                this.$message.info('请输入确认密码')
                return false
            } else if (this.formRegister.repassword != this.formRegister.password) {
                this.$message.info('两次输入的密码不一致')
                return false
            } else if (!this.formRegister.terms) {
                this.$message.info('请勾选协议')
                return false
            } else if (this.formRegister.type === '') {
                this.$message.info('请选择企业或者个体工商户')
                return false
            }
            let nowTime = new Date().getFullYear().toString() + (new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)).toString() + (new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate())
            this.$store.dispatch('getRegister', {
                USERNAME: this.formRegister.user,
                PASSWORD: this.formRegister.password,
                NAME:this.formRegister.orgName,
                FKEY: md5('USERNAME' + nowTime + ',ww,'),
                REGIST_TYPE: this.formRegister.type,
              PHONE:this.formRegister.phone,
                tm: new Date().getTime()
            }).then(res => {
                if (res.result == '00') {
                    this.$message.success('注册成功')
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 2000)
                } else {
                    if(res.result == '01'){
                        this.$message.info('用户名已经存在，请更改用户名')
                    }
                    if(res.result == '05'){
                        this.$message.info('不合法的注册')
                    }
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
/* 登录表单样式修改 */
.reg-form ::v-deep(.ant-form-item){
  margin-bottom: 12px;
}
.reg-form ::v-deep(.ant-input){
    padding: 2px 10px;
    border-left: 1px solid #fff;
    background-color:transparent;
    color: #fff;
}
.reg-form ::v-deep(.ant-input-prefix){
    color: #fff;
    margin-right: 10px;
}
.reg-form ::v-deep(.ant-input-affix-wrapper){
    background-color: rgba(0, 0, 0, .2);
    padding: 7px 11px;
}
.reg-form ::v-deep(.ant-input-affix-wrapper):hover{
    background-color: rgba(0, 0, 0, .4);
}
.ant-input-affix-wrapper:hover {
    border-color: #fff;
    border-right-width: 1px !important;
}
.ant-input-affix-wrapper:focus {
    border-color: #fff;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.4);
}
.ant-input-affix-wrapper-focused{
    border-color: #fff;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}
.reg-form ::v-deep(.ant-btn){
  width: 100%;
  padding:0 32px;
  color: #ffffff;
  margin-bottom: 10px;
  height: 42px;
  font-size:16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
  background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
  background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
  background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
  background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
}
.code-box{
    display: flex;
    justify-content: space-between;
}
.code-box::v-deep(.ant-btn){
    margin-bottom: 0;
    box-shadow: 0 0 0px rgba(0, 0, 0, .4);
}
.reg-form::v-deep(.ant-radio-wrapper){
    color: #fff;
    font-size: 16px;
}
.ant-radio-group{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.reg-form::v-deep(.ant-checkbox-wrapper){
    color: #fff;
    font-size: 14px;
}
.termssp{
    color: #0028bb;
    font-size: 14px;
    cursor: pointer;
}
</style>
<style scoped lang="less">
.register{
    height: 850px;
    display: flex;
    align-items: center;
}
.register-box{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .register-con{
        width:440px;
        height: 475px;
        padding: 15px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, .4);
        border:@border-ff;
        .reg-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom:1px solid rgba(255, 255, 255, .5);
            padding-bottom: 15px;
            p{
                font-size: @font-xl;
                color: #fff;
                margin-bottom: 0;
            }
            span{
                font-size: 24px;
                color: #fff;
            }
        }
        .reg-form{
            padding: 0 10px;
            padding-top: 15px;
        }
    }
}
</style>
